<template>
  <div>
    <slot :has-error="_errors.length > 0"></slot>

    <small v-if="_errors.length">
      <ul class="px-5 text-red-500 list-disc">
        <li v-for="(err, index) in _errors" :key="`field-error-${index}`">
          {{ err }}
        </li>
      </ul>
    </small>
  </div>
</template>

<script setup lang="ts">
import { useApiErrorsStore } from '~~/store/api_errors'

const props = defineProps<{ name?: string}>()

const { getError } = useApiErrorsStore()

const _errors = computed(() => {
  return getError(props.name)
})
</script>
